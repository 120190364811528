<!--

https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs

-->


<template>
  <div>
    <v-dialog
        v-model="show" max-width="750px" scrollable
        transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="text-h6 primary">
          Пополнить баланс
        </v-card-title>
        <v-card-text style="height: 400px;">

          <v-tabs :vertical="isNotMobile" class="mt-4" show-arrows>
            <v-tab>
              <v-icon left>mdi-credit-card-check-outline</v-icon>
              На карту
            </v-tab>
            <v-tab v-if="visible">
              <v-icon left>mdi-wallet-outline</v-icon>
              yoomoney
            </v-tab>
            <v-tab v-if="visible">
              <v-icon left> mdi-wallet</v-icon>
              Donatepay
            </v-tab>
            <v-tab v-if="visible">
              <v-icon left> mdi-credit-card-search-outline</v-icon>
              Другое
            </v-tab>

            <v-tab-item>
              <v-card flat>
                <v-card-text class="text-left">
                  <p>
                    Переведите любую сумму непосредственно на электронную карту: <br/>
                    VISA <b>4276 1609 3393 5729</b> Сбербанк<br/>
                    Сообщите время перевода и размер доната по почте <br/>
                    <b>zex@zex.ru</b> <br/>
                    или в личку ZEX в дискорте <br/>
                    <b>https://discord.gg/2KMuvsyq</b> <br/>
                  </p>

                  <p>
                    В ближайшее время ваш баланс будет пополнен.
                  </p>

                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <yandex/>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <donatepay/>
                  <!--                  <iframe-->
                  <!--                      src="https://widget.donatepay.ru/widgets/page/b5487eae03bb748b84e1fd93a9a661ee6eea77a7b87beef27840f4a1b1d08303?widget_id=1224498&sum=200"-->
                  <!--                      @load="onLoadtab('donatepay')" width="100%" height="220" frameBorder="0">-->
                  <!--                  </iframe>-->
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <div style="font-size: medium;text-align: center;">
                    Перевод можно сделать со станицы сайта <br/>
                    <a href="https://sobe.ru/na/TerraNova" target="_blank">https://sobe.ru/na/TerraNova</a> <br/>
                    или со станицы сайта <br/>
                    <a :href="'http://spaceengineers.world/terranova/донат?steamID=' + steamID" target="_blank">spaceengineers.world/terranova/донат</a>
                    <br/><br/>
                    <p>
                      Ваш SteamID:
                      <br/>
                      <b> {{ steamID }}</b>
                    <p/>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs>


        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="show = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
export default {
  props: {value: Boolean},
  data: function () {
    return {
      visible: false,
      steamID: this.$store.state.user.steam,
      loading: true,
    }
  },
  methods: {
    initialize() {
      if (this.$store.state.project.permission['finance.user'].status !== 'on') {
        this.visible = false
        return
      }
      this.visible = true
    },
  },
  mounted: function () {
    this.$bus.on("reloadPage", this.initialize);
  },
  created() {
    this.initialize()
  },
  computed: {
    isNotMobile() {
      return this.$vuetify.breakpoint.width > 414
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('close', value)
      }
    }
  },
  components: {
    "yandex": () => import('@/site/modules/finance/user-yandex'),
    "donatepay": () => import('@/site/modules/finance/user-donatepay'),
  }
}
</script>
